// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-page-js": () => import("/home/runner/work/Edpuzzle-Web/Edpuzzle-Web/src/frontend/blog/src/templates/post_page.js" /* webpackChunkName: "component---src-templates-post-page-js" */),
  "component---src-templates-category-page-js": () => import("/home/runner/work/Edpuzzle-Web/Edpuzzle-Web/src/frontend/blog/src/templates/category_page.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-pages-404-js": () => import("/home/runner/work/Edpuzzle-Web/Edpuzzle-Web/src/frontend/blog/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/home/runner/work/Edpuzzle-Web/Edpuzzle-Web/src/frontend/blog/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("/home/runner/work/Edpuzzle-Web/Edpuzzle-Web/src/frontend/blog/src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/runner/work/Edpuzzle-Web/Edpuzzle-Web/src/frontend/blog/.cache/data.json")

